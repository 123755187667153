/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import { graphql } from 'gatsby';
import { Formik, Form, Field, ErrorMessage as FormikErrorMessage } from 'formik';
import qs from 'qs';
import axios from 'axios';
import clsx from 'clsx';
import Layout from '../components/layouts/layout';
import SEO from '../components/seo';
import SupportFormSchema from '../schemas/support-form';

export const query = graphql`
  query GetContactPageSettings($locale: String!) {
    pageSettings: contentfulContactPageSettings(node_locale: { eq: $locale }) {
      title
      description {
        description
      }

      keywords {
        keywords
      }

      mainHeading
      textSectionContent {
        textSectionContent
      }
    }
  }
`;

function ContactPage({ data }) {
  const { pageSettings } = data;
  const fieldClasses = 'w-full rounded-default';

  const RequiredLabel = () => (
    <span className="text-xs warning px-1 rounded-full inline-block font-bold uppercase">
      Required
    </span>
  );

  const ErrorMessage = ({ name }) => (
    <FormikErrorMessage className="text-red-600 block" name={name} component="span" />
  );

  return (
    <Layout>
      <SEO
        title={pageSettings.title}
        description={pageSettings.description.description}
        keywords={pageSettings.keywords.keywords.split(',')}
      />
      <h1 className="text-center text-3xl sm:text-4xl md:text-5xl my-8 p-3 font-extrabold">
        Find someone to help you
      </h1>
      <Formik
        initialValues={{
          email: '',
          type: '',
          ticketDetails: '',
          accountURL: '',
        }}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true);
          try {
            const body = qs.stringify({
              'form-name': 'contact',
              ...values,
            });

            await axios.post('/?no-cache=1', body, {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            });
            actions.resetForm();
            actions.setStatus({
              success: true,
              message: `Your ticket was submitted successfully! We'll get back to you very soon`,
            });
          } catch (e) {
            actions.setStatus({
              success: false,
              message: 'Something went wrong with this request. Use the live chat, instead.',
            });
          }

          actions.setSubmitting(false);
        }}
        validateOnBlur
        validationSchema={SupportFormSchema}
      >
        {({ isSubmitting, status, errors }) => (
          <Form
            name="contact"
            className="mx-auto w-full max-w-4xl"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <p className="leading-loose mb-8 contact-page-description">
              Are you stuck and you need a helping hand? Just fill in the form below and we'd love
              to help you out.
            </p>

            {status && (
              <span
                name="form"
                className={`text-base ${
                  status.success ? 'text-green-700' : 'text-red-600'
                } inline-block font-bold`}
              >
                {status.message}
              </span>
            )}

            <p className="hidden">
              <label>
                Don’t fill this out if you&apos;re human: <input id="bot-field" name="bot-field" />
              </label>
              <input type="hidden" name="form-name" defaultValue="contact" />
            </p>

            <label className="block font-bold leading-loose mt-6 mb-2 text-xl" htmlFor="type">
              What can we help you with? <RequiredLabel />
            </label>
            <Field
              as="select"
              className={clsx(fieldClasses, { 'border border-red-600': !!errors.type })}
              id="type"
              name="type"
            >
              <option value="" id="choose" name="choose" defaultValue>
                Choose an option
              </option>
              {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
              <option value="I want to upgrade to a paid subscription" name="upgrade" id="upgrade">
                💳 I want to upgrade to a paid subscription
              </option>
              <option value="I can't access my account" name="locked-out" id="locked-out">
                I can't access my account
              </option>
              <option value="I'm having trouble signing up" name="site-issue" id="site-issue">
                I'm having trouble signing up
              </option>
              <option value="I got a question about billing" name="billing" id="billing">
                I got a question about billing
              </option>
              <option value="I want to ask for a refund" name="refunds" id="refunds">
                I want to ask for a refund
              </option>
              <option
                value="I want to request a feature"
                name="feature-request"
                id="feature-request"
              >
                I want to request a feature
              </option>

              <option value="I want an internship" name="internship" id="internship">
                I want an internship
              </option>
              <option value="Other" name="other" id="other">
                Other
              </option>
            </Field>
            <ErrorMessage name="type" />
            <label
              className="block font-bold leading-loose mt-6 mb-2 text-xl"
              htmlFor="ticketDetails"
            >
              What's your message to us? <RequiredLabel />
            </label>

            <Field
              as="textarea"
              className={clsx(fieldClasses, { 'border border-red-600': !!errors.ticketDetails })}
              placeholder="To be able to help you, give us as much detail as possible."
              name="ticketDetails"
              id="ticketDetails"
              rows="8"
            />
            <ErrorMessage name="ticketDetails" />

            <label className="block font-bold leading-loose mb-2 mt-6 text-xl" htmlFor="email">
              Email address <RequiredLabel />
            </label>

            <Field
              className={clsx(fieldClasses, { 'border border-red-600': !!errors.email })}
              type="text"
              id="email"
              name="email"
              placeholder="Enter your email address"
            />
            <ErrorMessage name="email" />

            <label className="block font-bold leading-loose mb-2 mt-6 text-xl" htmlFor="accountURL">
              Account URL
            </label>

            <Field
              className={clsx(fieldClasses, { 'border border-red-600': !!errors.accountURL })}
              type="text"
              id="accountURL"
              name="accountURL"
              placeholder="Enter your account URL"
            />
            <ErrorMessage name="accountURL" />

            <button
              type="submit"
              disabled={isSubmitting}
              className="mt-10 font-semibold text-lg w-full button button--outline"
            >
              Submit support request
            </button>
          </Form>
        )}
      </Formik>
    </Layout>
  );
}

export default ContactPage;
