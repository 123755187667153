import * as Yup from 'yup';

const INVALID_EMAIL = 'We need a working email address to reply back to you';
const SupportFormSchema = Yup.object().shape({
  email: Yup.string()
    .email(INVALID_EMAIL)
    .required(INVALID_EMAIL),
  ticketDetails: Yup.string().required('Tell us what is your question about'),
  type: Yup.string().required('Tell us what you need help with'),
  accountURL: Yup.string().url('Make sure this is a valid account url'),
});

export default SupportFormSchema;
